/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import 'ngx-toastr/toastr';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~leaflet/dist/leaflet.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  margin: 0;
  height: 100%;
  font-family: 'UberMove', sans-serif;
}

.section-title {
  margin-bottom: 5px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;

}

.section-body {
  display: flex;
  padding: 20px 10px 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #FFF;

}

// .toast-top-center {
//   margin: 35px auto;
//   font-size: 14px;
//   font-weight: 300;

// }

.toast-top-center {
  margin: 35px auto;
  font-size: 14px;
  font-weight: 300;
  text-align: center;

  i {
    font-size: 20px !important;
    /* Adjust tick icon size */
    margin-right: 8px;
    /* Add space between the icon and the text */
  }

  .toast-message {
    font-size: 16px !important;
    /* Font size for the message text */
    line-height: 1.4;
    /* Adjust line spacing if needed */
  }
}

.ngx-toastr {
  border-radius: 10px !important;
  /* Add smooth border-radius for the toast container */
}

.error-container {
  background: red;
  padding: 10px;
  margin: 10px 0;
  border-radius: 12px;
  color: white;
}

.rap-error-text {
  font-size: 12px;
  color: red;
  margin-top: 0px;
}

.form-group {
  margin-bottom: 0;
}

.input-container {
  margin-bottom: 10px;
}

label {
  font-weight: 600;
  font-size: 14px;

}

.card {
  display: flex;
  flex-direction: column;
  //border-radius: 0.5rem;
  //background-color: #D9D9D9;
  margin: 0rem 0 1.25rem 0;
  // box-shadow: 0 0.25rem 0.5rem #e6edef;

  .card-head {
    padding: 1rem 1.5rem;
    //  border-bottom: 2px solid #edf1f7;
    font-size: 1rem;
    font-weight: 600;
  }

  .card-body {
    padding: 1rem 1.5rem;
    width: 100%;
  }
}

thead {
  background-color: #E8E8E8;
  /* Light gray */
}

.table td,
.table th {
  // word-wrap: break-word;
  // max-width: 250px;
  padding: 15px 10px;
  border: none;
  font-family: 'UberMove', sans-serif;

}

.table td {
  padding-top: 30px;
  font-family: 'UberMove', sans-serif;
}


.table>thead>tr>th {
  background-color: #E8E8E8;
  font-family: 'UberMove', sans-serif;
  font-size: 700;
  font-size: 14px;
  color: black;
}

// /* Table Styling */
.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border: 1px solid #ddd;
}

/* Header Styling */
.custom-table thead th {
  background: #f0f0f0;
  // padding: 20px 25px;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap;
}

// /* Sorting Icons */
.sortable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-right: 0px;
}


.header-text {
  flex-grow: 1;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;

}

.arrow {
  // font-size: 7px;
  // color: #999;
  font-size: 7px;
  color: #888;
  line-height: 8px;
  opacity: 0.5;
  transition: color 0.2s, opacity 0.2s;
}

.arrow.active {
  color: #000;
  /* Darker for active arrow */
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  display: table-header-group;
  /* Ensures headers stay in a row */
}

tr {
  display: table-row;
  /* Ensures rows stay aligned */
}

th,
td {
  // padding: 10px;
  text-align: left;
  white-space: nowrap;
  /* Prevents text wrapping */
}

th {
  background-color: #eee;
  font-weight: bold;
}

.sortable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Table Rows */
.custom-table tbody td {
  padding: 20px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 15px !important;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999;
  color: #fff;
  background-color: black;
  // border: 4px solid black !important;
}

input.ng-invalid.submitted,
select.ng-invalid.submitted {
  border: 1px solid red;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* High z-index to ensure it covers everything */
}

.clickable-label {
  cursor: pointer;
  padding: 10px 10px;
  color: var(--Content-contentPrimary, #000);
  // font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.clickable-label:hover {
  color: var(--Content-hoverPrimary, grey);
  /* Replace with your desired hover color */
}

.non-clickable-label {
  cursor: default;
  padding: 10px 10px;
  color: var(--Content-contentPrimary, #000);
  // font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.label-container {
  display: flex;
  //margin: 10px auto;
  align-items: flex-start;


}

.button {
  display: flex;
  padding: 5px 20px;
  flex-direction: column;
  align-items: flex-start;
  //gap: 10px;

  background: var(--Inverse-Background-backgroundInversePrimary, #000);
  cursor: pointer;
}

// .search-container {
//   position: relative;
//   width: 100%;
//   max-width: 500px;
//   display: flex;
//   margin-bottom: 20px;
//   margin-right: 16px;
//   align-self: flex-end;
//   gap: 8px;
//   float: left;
//   border-radius: 4px;
// }





.view-queries-btn {
  margin-right: 12px;
  padding: 6px 23px;
  border-radius: var(--size-300, 12px);
  background: #E8E8E8;
  color: #000;

  text-align: center;
  /* Label/Small (14\16) */
  //font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

}

.delete-queries-btn {
  margin-right: 12px;
  padding: 6px 23px;
  border-radius: var(--size-300, 12px);
  background: #000;
  color: #FFF;

  text-align: center;
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.custom-container {
  border: 2px solid #E8E8E8;
  border-radius: 20px;
  // background-color: #f8f9fa;
  width: 100%;
  margin-bottom: 20px;
}

// .search-container input {
//   position: relative;
//   padding: 10px;
//   width: 100%;
//   width: 500px;
//   display: flex;
//   margin-bottom: 20px;
//   align-self: flex-end;
//   gap: 8px;
//   float: left;
//   border-radius: 4px;
//   background: var(--Background-backgroundPrimary, #E8E8E8);



//   .auth-icons {
//     position: absolute;
//     right: 15px;
//     top: 50%;
//     transform: translateY(-50%);
//     // width: 20px;
//     height: 20px;
//     // background-color: red;
//     align-items: row;

//   }

//   .auth-icons-text {
//     position: absolute;
//     right: 40px;
//     top: 43%;
//     transform: translateY(-50%);
//     // width: 20px;
//     height: 20px;
//     // background-color: red;
//     align-items: row;

//   }

//   .search-icon {
//     font-size: 20px;
//     color: #666;
//     margin-left: 15px;
//   }

//   .clear {
//     right: 60px;
//     width: 15px;
//     height: 15px;
//   }

//   .search-clear-button {
//     // margin: 10px;
//     border: none;
//     background-color: black;
//     color: white;
//     border-radius: 5px;
//     font-size: 10px;
//     cursor: pointer;
//   }
// }

// .search-container input:focus {
//   border: 2px solid black;
// }

// .search-container input:not(:focus) {
//   border: none;
// }

.rect-secondary-btn {
  // margin-right: 10px;
  background-color: #E8E8E8;
  min-width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  margin-right: 2px;

}

.rect-primary-btn {
  min-width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.rect-primary-btn1 {
  min-width: 120px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.rect-secondary-btn1 {
  // margin-right: 10px;
  background-color: #E8E8E8;
  min-width: 120px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

}

.btn {
  font-family: 'UberMove', sans-serif !important;
}

.action-btns {
  display: flex;
  gap: 2px;
  margin-top: 20px;

  .action-button {
    display: flex;
    padding: 10px 50px;
    //background: #E8E8E8;
    background: #E8E8E8;
  }

}

.pill-secondary {
  align-self: self-end;
  background-color: #000;
  border-radius: 28px;
  padding: 6px 20px;
  // margin-left: 10px;
  color: white;
}

.pill-secondary-light {
  align-self: self-end;
  background-color: #E8E8E8;
  border-radius: 28px;
  padding: 6px 15px;
  // margin-left: 10px;
  color: black;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  /* Hide the default checkbox */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Styling the custom checkbox */
  width: 20px;
  height: 20px;
  border: 3px solid #5E5E5E;
  /* Black border */
  border-radius: 2px;
  /* Optional: Make it rounded */
  background-color: white;
  /* Default background color */
  cursor: pointer;

  /* Add a black checkmark when checked */
  position: relative;
}

.custom-checkbox:checked {
  background-color: black;
  border: 3px solid black;
  /* Black background when checked */
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.no-match {
  color: var(--Content-contentPrimary, #000);
  text-align: center;
  // font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.25px;
}

@font-face {
  font-family: 'UberMove';
  src: url('assets/fonts/UberMoveTextRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UberMove';
  src: url('assets/fonts/UberMoveTextBold.ttf') format('truetype');

  font-weight: 700;
}

@font-face {
  font-family: 'UberMove';
  src: url('assets/fonts/UberMoveTextMedium.ttf') format('truetype');
  font-weight: 400;

}

@font-face {
  font-family: 'UberMove';
  src: url('assets/fonts/UberMoveTextLight.ttf') format('truetype');
  font-weight: 300;

}

.error {
  color: red;
}


input.is-invalid {
  border-color: red;
}

.required {
  color: red;
}

// .ng-multiselect-dropdown .dropdown-btn {
//   background-color: #f8f9fa;
//   border: 1px solid #ced4da;
// }

// .ng-multiselect-dropdown .dropdown-item {
//   padding: 5px 10px;
//   border-bottom: 1px solid #e9ecef;
// }

// .ng-multiselect-dropdown .dropdown-item:hover {
//   background-color: #f1f1f1;
// }

input-dob {
  padding: 8px;
  font-size: 14px;
  width: 100%;
  max-width: 500px;
  border: 1px solid #ccc;
  padding: 0.5rem;
  /* Adjust width as needed */
  // box-sizing: border-box;
}

.role-action-btns {
  display: flex;
  gap: 4px;
  margin-top: 20px;

  .action-button {
    display: flex;
    padding: 10px 50px;
    //background: #E8E8E8;
    background: #E8E8E8;
  }

}

.button {
  display: flex;
  padding: 5px 20px;
  flex-direction: column;
  align-items: flex-start;
  //gap: 10px;

  background: var(--Inverse-Background-backgroundInversePrimary, #000);
  cursor: pointer;
}


tbody {
  font-family: 'Uber Move', sans-serif;
  font-size: 15px;
  font-weight: 300;
}


.table-body-light {
  font-family: 'UberMove', sans-serif;
  font-weight: 300;
}

.sign-in {
  height: 100vh;
  display: flex;
  margin: 0;
}

.create-account {
  // height: 100vh;
  display: flex;
  margin: 0;
}

.left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: transparent;
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* High z-index to ensure it covers everything */
  }
}

.right {
  flex: 1;
  background-color: #000;
}

.container {
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;

}

p {
  color: var(--Content-contentPrimary, #000);
  margin-bottom: 2rem;
}


.help-text {
  color: #777;
  margin-top: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.help-text:hover {
  color: #333;

}

.btn-signin {
  width: 100%;
  max-width: 400px;
  background-color: #000;
  padding: 0.7rem 1rem;
  border-radius: 8px;
  font-size: 16px;
  color: #FFF;
}

.btn-create {
  background-color: #e0e0e0;
  color: #000;
  width: 100%;
  max-width: 400px;
  padding: 0.7rem 1rem;
  border-radius: 8px;
  font-size: 16px;
}

input.ng-invalid.submitted {
  border: 1px solid red;
}

// .input-container {
//   position: relative;
//   width: 100%;
// }

// .input-container {
//   .auth-icons {
//     position: absolute;
//     right: 15px;
//     top: 50%;
//     transform: translateY(-50%);
//     // width: 20px;
//     height: 20px;
//     // background-color: red;
//     align-items: row;

//   }

//   .clear {
//     right: 60px;
//     width: 15px;
//     height: 15px;
//   }
// }

.input-container-read {
  display: flex;
  align-items: center;
  gap: 8px;
  // width: 500px;
  border-radius: 8px;
  background: #f5f5f5;
  padding: 4px 8px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  // width: 50%;
}

.input-container-read input.search-input-read {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  color: #aaa;
  background: transparent;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  // width: 500px;
  border-radius: 8px;
  background: #f5f5f5;
  padding: 4px 8px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}


.input-container:focus-within {
  border-color: black;
}


.input-container input.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  color: #333;
  background: transparent;
}

.input-container input.search-input::placeholder {
  color: #aaa;
}


.input-container .auth-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input-container .auth-icons i {
  font-size: 13px;
  color: black;

  transition: color 0.3s ease;
}

.input-container .auth-icons i:hover {
  color: #000;
}

.input-container .search-icon {
  width: 12px;
  height: 12px;

  color: #666;

}

.options {
  padding: 10px;
  margin: 16px 0px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;

}

.options.selected {
  // background-color: #007bff;
  color: black;
  border: 3px solid var(--Border-borderSelected, #000);
}

.toast-success {
  font-size: 16px; // Adjust the font size for the message
  display: flex;
  align-items: center;

  .toast-message {
    font-size: 16px; // Font size for the success message text
  }

  .toast-success-icon {
    font-size: 24px; // Adjust the tick mark icon size
    margin-right: 8px; // Add spacing between the icon and text
  }
}

.multi-dropdown {
  border-radius: 8px;
  background: #f5f5f5;
  padding: 0px 0px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;

}

$base-color: #000;
$disable-background-color: #eceeef;

.multiselect-dropdown {
  position: absolute;
  width: 100%;
  font-size: inherit;
  font-family: inherit;


  .dropdown-btn {
    display: inline-block;
    border: none !important;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.52857143;
    // text-align: left;
    // vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 8px;
    background-color: #f5f5f5 !important;

    .selected-item {
      border: 1px solid $base-color !important;
      margin-right: 4px;
      background: $base-color !important;
      padding: 0px 5px;
      color: #fff;
      border-radius: 2px;
      // float: left;
      width: 500px;

      a {
        text-decoration: none;
      }
    }

    .selected-item:hover {
      box-shadow: 1px 1px #959595;
    }

    .dropdown-down {
      // display: inline-block;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    .dropdown-up {
      // display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }

  .disabled {
    &>span {
      background-color: $disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0px 1px 5px #959595;

  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
  }

  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }

  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;

    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }

    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus+div:before,
.multiselect-item-checkbox input[type='checkbox']:hover+div:before {
  border-color: $base-color !important;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox']:active+div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox']+div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
}

.multiselect-item-checkbox input[type='checkbox']+div:before {
  box-sizing: content-box;
  content: '';
  color: $base-color !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid $base-color !important;
  text-align: center;
  transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type='checkbox']+div:after {
  box-sizing: content-box;
  content: '';
  background-color: $base-color !important;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled+div:before {
  border-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:focus+div:before .multiselect-item-checkbox input[type='checkbox']:disabled:hover+div:before {
  background-color: inherit;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:checked+div:before {
  background-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox']+div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:before {
  animation: borderscale 200ms ease-in;
  background: $base-color !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:after {
  transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base-color !important;
  }
}


.custom-ng-select {
  --ng-select-border: 2px solid #f5f5f5;
  --ng-select-border-radius: 6px;
  --ng-select-background: #f5f5f5;

  .ng-select-container {
    padding: 12px 0px;
    border: var(--ng-select-border);
    border-radius: var(--ng-select-border-radius);
    background-color: var(--ng-select-background);
  }

  .ng-select-container:hover {
    border-color: black;
  }


  .ng-select-container.ng-select-single.ng-select-touched {
    background-color: #f5f5f5;

  }

  .ng-select-dropdown {
    border-radius: 12px;
    background-color: #ffffff;
  }
}